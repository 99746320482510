.chart-legend {
    font-family: Frutiger;
    &--items {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        &-color {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            display: flex;
            align-items: center;
        }
        &-text {
            margin-left: 13px;
            font-size: 0.6875rem;
            font-weight: 400;
            line-height: 7px;
        }
    }
    .legend {
        display: flex;
        justify-content: center;
        margin-left: -30px;
        span {
            cursor: pointer;
            font-size: 11px;
            font-weight: 600;
            color: #4a4a4a;
            margin-bottom: 3px;
        }
        span:nth-child(1) {
            margin-right: 20px;
        }
    }
}