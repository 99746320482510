.custom-date-field-wrapper {
    display: flex;
    flex-direction: column;

    &:first-child {
        margin-bottom: 13px;
    }
    .MuiPickersCalendarHeader-iconButton {
        background-color: transparent;
    }
    .MuiPickersToolbar-toolbar {
        background-color: yellow !important;
    }
    .MuiPickersYear-root:focus,
    .MuiTypography-colorPrimary {
        color: yellow !important;
    }
    .MuiButton-label {
        color: yellow;
    }
    .MuiDialogActions-root {
        button {
            .MuiButton-label {
                visibility: hidden;
                &:after {
                    margin-left: -60px;
                    content: 'Cancelar';
                    visibility: visible;
                }
            }
            &:last-child {
                .MuiButton-label {
                    &:after {
                        margin-left: -20px;
                        content: 'Alterar';
                    }
                }
            }
        }
    }
    .MuiButtonBase-root {
        &.MuiIconButton-root {
            &.MuiPickersDay-day {
                &.MuiPickersDay-daySelected {
                    background-color: yellow;
                    &.MuiPickersDay-current {
                        color: white;
                    }
                    &.MuiPickersDay-dayDisabled {
                        color: white;
                    }
                    &:hover {
                        background-color: yellow;
                    }
                }
                &.MuiPickersDay-current {
                    color: yellow;
                }
            }
        }
    }
    .icon-calendario {
        font-size: 24px;
    }
    .custom-date-field-wrap {
        display: flex;
        width: 100%;
        height: 34px;
        cursor: pointer;
        border-radius: 6px;
        padding-left: 16px;
        align-items: center;
        // background-color: #F6F6F6;
        border: solid 1px #D8D8D8;
        .MuiInputBase-root {
            font-size: 15px;
            font-family: 'Open Sans';
            color: #868686;
        }
        .MuiIconButton-root:hover,
        .MuiIconButton-root:active {
            background: none;
        }
        .MuiTouchRipple-root {
            display: none;
        }
        .MuiInputAdornment-positionEnd {
            margin-left: 0;
        }
        //&.on-error {
        //  border-radius: 6px;
        //  border-top: 2px solid $error-one !important;
        //}
        //
        //&.on-valid {
        //  border-radius: 6px;
        //  border-top: 2px solid $valid-one !important;
        //}
        .MuiFormControl-marginNormal {
            margin: unset;
        }
        .MuiFormControl-root {
            display: flex;
            width: 100%;
            height: 100%;
            padding-top: 5px;
            padding-right: 20px;
            justify-content: center;
            .MuiFormHelperText-root {
                display: none;
            }
            .MuiIconButton-label {
                color: #149372;
            }
            .MuiInputBase-root {
                width: 100%;
                cursor: none;
                pointer-events: none;
                 ::placeholder {
                    opacity: 0.7;
                }
                .MuiInputBase-input {
                    padding: unset;
                    margin-top: -5px;
                }
                .MuiInputAdornment-root {
                    .MuiButtonBase-root {
                        padding: unset;
                        cursor: pointer;
                        pointer-events: all;
                        &:focus {
                            outline: unset;
                        }
                        .MuiIconButton-label {
                            margin-top: -6px;
                        }
                    }
                    .MuiIconButton-label {}
                }
            }
        }
    }
    .error-label {
        display: flex;
        width: 100%;
        padding-left: 14px;
        span {
            font-size: 12px;
            padding-top: 2px;
            color: red;
            letter-spacing: 0.54px;
        }
    }
}