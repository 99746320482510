.notifications__container {
  height: 100vh;
  overflow: auto;
  
  .content {
    height: 100vh;
    padding: 66px 0 36px;
    margin-top: -66px;
    display: flex;
  }

  .content-left,
  .content-right {
    flex: 0 0 50%;
    overflow-y: auto;
    height: 100%;
    position: relative;
  }

  &--header {
    display: flex;
    align-items: center;
    padding: 0 26px;
    height: 66px;
    border-bottom: 1px solid #d8d8d8;

    &-title {
      font-size: 20px;
      font-weight: 600;
      color: black;
    }
  }

  &--messages {
    background: #f8f8f8;
    padding: 15px 35px 0 25px;

    &-background {
      background: white;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      padding: 20px 20px 10px 20px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 12px;

      .triangle-img {
        width: 0;
        height: 0;
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-right: 11px solid white;
        position: absolute;
        left: 17px;
        filter: drop-shadow(-2px 1px 1px #d8d8d8);
      }
    }

    &-title {
      color: #4a4a4a;
      font-size: 14px;
      margin-bottom: 12px;
    }

    &-text {
      font-size: 14px;
      color: #7b7b7b;
      margin-bottom: 12px;
      &-title {
        font-size: 16px;
        color: black;
        font-weight: 700;
        margin-bottom: 5px;
      }
      &-date,
      &-info {
        font-size: 12px;
      }

      button {
        font-size: 14px;
        text-transform: uppercase;
        color: #149372;
        font-weight: 600;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }
    }
  }

  &--line {
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #e3e3e3;
    padding: 0 20px 0 25px;
    cursor: pointer;

    &:first-child {
      border: none;
    }

    &:last-child {
      border-bottom: 1px solid #e3e3e3;
    }

    &:active {
      background: #f4f4f4;
    }
  }

  &--line + &--line:active {
    border-color: transparent;
  }

  .notification-active {
    width: 8px;
    height: 8px;
    background: #e40b21;
    border-radius: 50%;
    margin-right: 16px;
  }

  .notification-disable {
    background: transparent;
  }
  .notification-title {
    font-size: 14px;
    color: #494a4a;
  }

  .notification-date {
    font-size: 12px;
    color: #a4a4a4;
  }
  .notification-description {
    font-size: 13px;
    color: #7b7b7b;
  }
}
