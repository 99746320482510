$pumpkin: #ef7d00;
$mango: #f59e24;
$orange-yellow: #f5bc14;
$bland: #b4af8d;
$dark-beige: #918e7a;
$dusty-teal: #4e9a98;
$dark-blue-grey: #1a3c47;
$pumpkin-orange: #f17d00;
$orange: #f1870b;
$tangerine: #fe990b;
$dusty-orange: #f56f3d;
$blue-grey: #9199a1;
$ice-blue: #e8ebec;
$greyish-brown: #4a4a4a;
$white: #fafafa;
$maize: #fdcb41;
$black: #000000;
$white-two: #f6f6f6;
$greyish-teal: #5ab0ae;
$brownish-grey: #707070;
$white-three: #ffffff;
$white-four: #f5f5f5;
$aqua: #0bdad5;
$fern-green: #478b46;
$golden: #f6bd00;
$very-light-pink: #b9b9b9;
$very-light-pink-two: #f0f0f0;
$very-light-pink-three: #eeeeee;
$maize-two: #fecf44;
$nice-blue: #0f74a9;
$black-two: #1c1c1c;
$pale-lilac: #ebebec;
$light-periwinkle: #e1e2e6;
$very-light-pink-four: #e3e3e3;
$brown-grey: #9b9b9b;
$white-five: #fbfbfb;
$beige: #d0c9c9;
$deep-red: #bd0300;
$very-light-pink-five: #e7e7e7;
$brown-grey-two: #a4a4a4;
$charcoal-grey: #363641;
$brown-grey-three: #878787;
$dark-grey: #131415;
$very-light-pink-six: #ebebeb;
$very-light-pink-seven: #d1d1d1;
$purplish-grey: #6e6d6e;
$marine: #072056;
$greyish-teal-two: #61b4b2;
$tangerine-two: #ef8c0a;
$brown-grey-four: #bfbfbf;
$brown-grey-five: #a8aba4;
$error-one: #e04444;
$error-two: #f44336;
$valid-one: #5ab0ae;
$brown-grey-six: #f1f1f1;
$brown-grey-seven: #717171;
$brown-grey-eight: #ABABAB;
$brown-grey-nine: #6e7475;
$brown-grey-ten: #747a7b;
$white-six: #F3F3F3;
