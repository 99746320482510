.footer__container {
    width: 100%;
    height: 36px;
    background: white;
    position: absolute;
    bottom: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-top: solid 1px #d8d8d8;
    bottom: 0;
    &--text {
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: 300;
        color: #686868;
    }
}