@import "../../components/responsive/media_query.scss";

#plantao-cadastro {
  .card {
    width: 100%;
    max-width: 1000px;
    overflow: auto;
    height: 100%;
    margin: 0 auto auto;
  }

  .btn-primary {
    background-color: #009776;
    border: none;
    color: white;
    padding: 3px 26px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 26px;
    text-shadow: 0 0 0.2em #8f7;
    @include responsive(s) {
      padding: 12px 33px;
    }
  }

  .btn-primary-mobile {
    @include responsive(s) {
      width: 100%;
    }
  }

  .card-title {
    color: #00c4a3;
  }
  .p-t-40 {
    padding-top: 80px;
  }

  .date-custom {
    display: block;
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .campo-invalido {
    border: 1px solid red !important;
    border-radius: 0.375rem;
  }

  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-17 {
    margin-top: 17px;
  }
  .m-t-12 {
    margin-top: 12px;
  }
  .m-l-22 {
    margin-left: 22px;
  }
  .input-custom {
    display: block;
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 30000;
  }

  .react-datepicker-wrapper {
    @include responsive(s) {
      width: 100%;
    }
  }

  .pb-5,
  .py-5 {
    padding-bottom: 0 !important;
  }
}
