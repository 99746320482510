.base-selectfield__link {
    div.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
        width: 95px;
    }
    select.MuiSelect-root.MuiSelect-select.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input {
        padding: 18px 12px;
    }
}

.base-selectfield__payment {
    div.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
        width: 95px;
        margin: 0 auto;
    }
    select.MuiSelect-root.MuiSelect-select.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input {
        padding: 18px 12px;
    }
}

.base-selectfield__payer {
    div.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
        width: 95px;
    }
    select.MuiSelect-root.MuiSelect-select.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input {
        padding: 18px 12px;
    }
}

.base-selectfield__cnes {
    width: 150px;
    div.custom-date-field-wrapper > div.custom-date-field-wrap > div.MuiFormControl-root {
        padding-right: 12px;
    }
    div.custom-date-field-wrapper {
        margin-bottom: 0px;
    }
}

td.MuiTableCell-root {
    padding: 6px;
    vertical-align: middle;
}

td.MuiFormControl-root.makeStyles-formControl-1 {
    padding: 0px;
    background-color: blue;
    
}

td.MuiTableCell-root.MuiTableCell-body {
    // align-items: center;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader {
    // padding-left: 0px;
    // min-width: 137px;
}
