.menu-page__container {
    text-decoration: none;
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 13px;
    color: #4a4a4a;
    font-weight: 400;
    cursor: pointer;
    padding: 0;
    margin: 0;
    height: 24px;
    &--items {
        margin-left: 20px;
        height: 24px;
    }
    &--items:first-child {
        margin-left: 0;
    }
    &--items:hover {
        color: #149372;
        transition: 50ms;
    }
    &--items[data-active="true"] {
        border-bottom: 2px solid #149372;
        color: #149372;
        transition: 50ms;
        border-radius: 2px;
    }
}