@import "../../components/responsive/media_query.scss";


#escala-visualizar {
  @include responsive(s) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .table__mobile {
    display: none;
    @include responsive(s) {
      display: block;
      table-layout: fixed;
      width: 100%;

      .table__mobile--header {
        width: 120px;
        color: #8898aa;
        background-color: #f6f9fc;
        color: #8898aa;
        border-right: 1px solid #e9ecef;
      }
    }
  }

  .card {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    overflow: auto;
    height: 100%;
    margin-top: 50px !important;
    @include responsive(s) {
      margin-top: 0 !important;
    }
  }

  .card-body-mobile {
    @include responsive (s) {
      padding: 0;
    }
  }

  .btn-primary {
    background-color: #009776;
    border: none;
    color: white;
    padding: 3px 37px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 26px;
    text-shadow: 0 0 0.2em #8F7;
    @include responsive(s) {
      padding: 12px 33px;
      width: 100%;
    }
  }

  .card-title {
    color: #00c4a3;
  }

  .clip-loader-visualizar{
    margin-left: 45%;
    position: absolute;
  }

  .b-r-1{
    border-radius: 1.375rem !important;
  }
  .w-95{
    width: 95% !important;
  }

  .table__web {
    @include responsive(s) {
      display: none;
    }
  }
}
