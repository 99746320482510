.MuiFilledInput-root .MuiSvgIcon-root {
    margin: 0;
}

.floating-label-custom-autocomplete-code {
    div.MuiFilledInput-root {
        background: white;
        border: solid 1px #d8d8d8;
        border-radius: 6px;
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 300;
        color: #404040;
        overflow: hidden;
        padding-top: 0;
        position: relative;

        .MuiFilledInput-input {
            padding-right: 4px;
        }

        .MuiAutocomplete-endAdornment {
            top: 2px;
        }
    }

    label.MuiInputLabel-shrink {
        transform: translate(9px, 6px) scale(0.75) !important;
    }
}

.style-listbox {
    position: absolute;
    top: -4px;
    left: -1px;
    min-height: 50px;
    width: 154px;
    background: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    border-radius: 0 0 5px 5px;
    font-size: 13px;
    font-weight: 300;
    color: #404040;
    font-size: 13px;
}
