#usuarioApp-visualizar {

  .card {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    overflow: auto;
    height: 100%;
    margin-top: 50px !important;
  }

  .btn-primary {
    background-color: #009776;
    border: none;
    color: white;
    padding: 3px 37px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 26px;
    text-shadow: 0 0 0.2em #8F7;
  }

  .card-title {
    color: #00c4a3;
  }

  .clip-loader-visualizar{
    margin-left: 45%;
    position: absolute;
  }

  .b-r-1{
    border-radius: 1.375rem !important;
  }
  .w-95{
    width: 95% !important;
  }
  .w-300{
    width:300px;
  }
  .p-t-30{
    padding-top: 30px;
  }
  .p-t-15{
    padding-top: 15px;
  }
  .cursor-custom:hover{
    cursor: pointer;
    color: #00c4a3;
  }
  .p-l-3{
    padding-left: 3px;
  }
  .p-l-5{
    padding-left: 5px;
  }
  .mt-10{
    margin-top: 10px;
  }
  textarea {
    resize: none;
    height: 100px;
  }

  .m-b-2p {
    margin-bottom: 2%;
  }


  .m-l-15px {
    margin-left: 15px !important;
  }

  .modal-body > textarea {
    margin-top: 3%;
  }

}
