@import "../../../.../../../components/responsive/media_query.scss";

#preferences-medic {

  .card-title-mobile {
    @include responsive(s) {
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }

  .card {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    overflow: auto;
    height: 100%;
    margin-top: 50px !important;
  }

  .btn-primary {
    background-color: #009776;
    border: none;
    color: white;
    padding: 3px 37px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 26px;
    text-shadow: 0 0 0.2em #8f7;
  }

  .card-title {
    color: #00c4a3;
  }

  .text-red {
    color: red;
  }

  .b-r-1 {
    border-radius: 1.375rem !important;
  }

  .w-95 {
    width: 95% !important;
  }

  .w-300 {
    width: 300px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-t-17 {
    margin-top: 17px;
  }

  .m-t-12 {
    margin-top: 12px;
  }

  .m-l-22 {
    margin-left: 22px;
  }

  .p-t-35 {
    padding-top: 35px;
  }

  .p-t-14 {
    padding-top: 14px;
  }

  .p-l-45 {
    padding-left: 45px;
  }

  .p-l-5 {
    padding-left: 5px;
  }

  .m-t-30 {
    margin-top: -30px;
  }

  .cursor-custom:hover {
    cursor: pointer;
    color: #00c4a3;
  }

  .custom-input {
    display: block;
    width: 100%;
    height: calc(2.75rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
}

.campo-invalido {
  border: 1px solid red !important;
  border-radius: 0.375rem !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #adb5bd;
  opacity: 1; /* Firefox */
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.checkbox-form-control {
  display: flex;
  align-items: center;
  span {
    padding-left: 5px;
  }
}
