.contract-detail__container {
  &--header {
    height: 66px;
    border-bottom: 1px solid #d8d8d8;
    padding: 5px 26px 0 26px;   

    &-title {
      font-size: 18px;
      color: black;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  &--body-subtitle {
    font-size: 13px;
    color: #1d1e1f;
    font-weight: 600;
    margin: 28px 0 10px 0;
  }

  &--body {
    padding: 24px 26px;
    min-height: calc(100vh - 102px);

    .base-selectfield {
      min-width: 222px;
      max-width: 222px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      .MuiInputLabel-filled {
        transform: translate(12px, 12px) scale(1);
      }
    }

    &-filter-date {
      div.custom-date-field-wrapper:first-child {
          margin-bottom: 13px !important;
      }
    }

    &-table {
      td.MuiTableCell-root.MuiTableCell-body.td-first {
        padding: 1rem;
        line-height: 100%;
      }
      td.MuiTableCell-root.MuiTableCell-body.td-last {
        display: table-cell;
      }
      div.icon-dots {
        margin: 0px;
      }
    }

    &-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      font-weight: 600;
      color: black;
    }

    &-date {
      .row {
        height: 52px;
        background: #f8f8f8;
        border-bottom: solid 1px #e3e3e3;
        display: flex;
        align-items: center;
        margin: 0;
      }

      .row:last-of-type {
        border: none;
      }
      &-title {
        font-size: 14px;
        font-weight: 600;
        color: black;
        margin-bottom: 8px;
        margin-top: 16px;
      }

      &-caption {
        font-size: 12px;
        color: #747474;
        margin-bottom: 60px;
        margin-top: 8px;
        padding-left: 15px;
      }

      &-fields {
        .multiple-questions {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .body-fields--question {
          font-size: 0.75rem;
          color: #7b7b7b;
          line-height: 12px;
        }
        .body-fields--answer {
          font-size: 0.875rem;
          color: #3e3e3e;
          line-height: 21px;
        }
      }
    }

    &-tags {
      span {
        background-color: #9df2da;
        border-radius: 10px;
        padding: 2px 10px;
        text-transform: uppercase;
        font-size: 11px;
        color: #3e3e3e;
        font-weight: 600;
        margin: 0 4px;
      }

      span:first-of-type {
        margin: 0 4px 0 0;
      }
    }

    &-archive {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #149372;

      &:hover {
        color: #149372;
      }

      span {
        text-decoration: underline;
        margin-left: 5px;
      }
    }

    &-note-text {
      font-size: 14px;
      color: #3e3e3e;
      padding: 16px;
      background: #f8f8f8;
    }

    &-margin {
      border-radius: 6px;
      border: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .MuiTableCell-stickyHeader {
      z-index: 0;
    }

    &-filter-status {
      div.paper-filter {
        top: 22px !important;

        div.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
          transform-origin: center top !important;
        }
      }
    }
  }

  .dropdown__filter-scale {
    font-size: 12px;
    color: #3e3e3e;
    height: 36px;
    background: white;
    padding: 10px 12px 18px 12px;
    border-radius: 5px;
    position: absolute;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
    width: 270px;
    height: 150px;

    &-title {
      font-size: 12px;
      color: #404040;
      margin-bottom: 12px;
    }

    .MuiInputLabel-filled {
      transform: translate(10px, 13px) scale(1);
      font-size: 13px;
      font-family: 'Open Sans';
    }

    div.MuiFilledInput-root {
      background: #f6f6f6 !important;
    }

    .triangle-yellow {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid white;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      bottom: 18px;
    }
  }

  .dropdown__filter-status {
    font-size: 12px;
    color: #3e3e3e;
    height: 36px;
    background: white;
    padding: 10px 12px 18px 12px;
    border-radius: 5px;
    position: absolute;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
    width: 270px;
    height: 180px;

    &-title {
      font-size: 12px;
      color: #404040;
      margin-bottom: 12px;
    }

    .MuiInputLabel-filled {
      transform: translate(10px, 13px) scale(1);
      font-size: 13px;
      font-family: 'Open Sans';
    }

    div.MuiFilledInput-root {
      background: #f6f6f6 !important;
    }

    .triangle-yellow {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid white;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      bottom: 18px;
    }
  }
}

.control-modal__container{
  &--header{
    height: 66px;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 18px;
      color: black;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  &--body{
    min-height: calc(100vh - 102px);
    padding: 24px 26px 0 26px;


    .MuiToggleButton-root {
      padding: 0 0 2px 0;
      height: 30px;
      width: 86px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      color: #7B7B7B;
      border-radius: 6px;
      margin-left: 10px;
    }

    .MuiToggleButton-root.Mui-selected, 
    .MuiToggleButton-root.Mui-selected:hover {
      background-color: #D8D8D8;
      color: #898D8F;
    }

    .MuiToggleButton-label{
      text-transform: none;
      font-size: 11px;
      font-weight: 600;
      font-family: 'Open Sans';
    }
  }

  .calendar-modal--title {
    font-size: 12px;
    color: #3E3E3E;
    font-weight: 600;
  }
}

.contract-control__container {
  div.paper-filter {
    .floating-label {
      div.MuiFilledInput-root .MuiFilledInput-input {
        padding: 9px 0 9px 12px !important;
      }
    }
  }
}