#evento-cadastro {
  .card {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    height: 100%;
    margin-top: 50px !important;
    overflow-x: auto;
  }

  .over-table {
    max-height: 500px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .picker {
    .rdtPicker {
      display: none;
      position: absolute;
      padding: 20px;
      margin-top: 1px;
      z-index: 99999 !important;
      background: #fff;
      border-radius: .375rem;
      box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      min-width: 160px;
    
      &:before {
        display: inline-block;
        position: absolute;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: "";
        top: -5px;
        left: 10px;
        right: auto;
        color: #ffffff;
        border-bottom: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
      }
      &:after {
        border-bottom: 0.4em solid #ffffff;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
        content: "";
        display: inline-block;
        position: absolute;
        top: -5px;
        left: 10px;
      }
    }
    
    .rdtPicker {
      display: block;
      top: 40px;
    }
    .rdtStatic .rdtPicker {
      box-shadow: none;
      position: static;
    }
    
    .rdtPicker .rdtTimeToggle {
      text-align: center;
      padding: 10px;
      border-radius: 4px;
      visibility: hidden;
      position: relative;
      &:before {
        visibility: visible;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal normal 1em/1 "Nucleo";
        font-size: 18px;
        content: "Selecionar horário";
      }
    }
  }

  .btn-primary {
    background-color: #009776;
    border: none;
    color: white;
    padding: 3px 37px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 26px;
    text-shadow: 0 0 0.2em #8f7;
  }

  .card-title {
    color: #00c4a3;
  }

  .p-t-40 {
    padding-top: 40px;
  }
  .campo-invalido {
    border: 1px solid red;
    border-radius: 0.375rem;
  }

  .b-r-1 {
    border-radius: 1.375rem !important;
  }
  .w-95 {
    width: 95% !important;
  }

  .top-down-button-container {
    position: absolute;
    right: 60px;
    top: 35px;
    img {
      margin-right: 20px;
    }
  }

  .image {
    object-fit: contain;
    width: 200px;
  }
}
