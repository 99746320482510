@import '../../components/responsive/media_query.scss';

#escala-cadastro {
    .checkbox {
        all: unset;
        border: 1px solid black;
        width: 15px;
        height: 15px;
        display: inline-block;
    }

    .card {
        width: 100%;
        max-width: 1000px;
        margin: auto;
        height: 100%;
        margin-top: 50px !important;
        overflow-x: auto;
        @include responsive(s) {
            margin-top: 0 !important;
        }
    }

    .bg-transparent.pb-5.card-header {
        @include responsive(s) {
            display: flex;
            flex-direction: column;
            .button__action--first {
                order: 1;
                margin-top: -15px;
            }
            .button__action--second {
                order: 2;
            }
            .button__action--third {
                order: 3;
                margin-bottom: 20px;
            }
        }
    }

    .table th,
    .table td {
        @include responsive(s) {
            padding: 10px 24px;
        }
    }

    .over-table {
        max-height: 500px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .btn-primary {
        background-color: #009776;
        border: none;
        color: white;
        padding: 3px 37px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        border-radius: 26px;
        text-shadow: 0 0 0.2em #8f7;
        @include responsive(s) {
            padding: 12px 33px;
        }
    }

    .btn-primary-mobile {
        @include responsive(s) {
            width: 100%;
        }
    }

    .card-title {
        color: #00c4a3;
    }

    .pb-5,
    .py-5 {
        @include responsive(s) {
            padding-bottom: 0 !important;
        }
    }

    .p-t-40 {
        padding-top: 40px;
    }
    .campo-invalido {
        border: 1px solid red;
        border-radius: 0.375rem;
    }

    .b-r-1 {
        border-radius: 1.375rem !important;
    }
    .w-95 {
        width: 95% !important;
        @include responsive(s) {
            width: 92% !important;
            margin-top: 20px !important;
            margin-bottom: 20px !important;
        }
    }

    .top-down-button-container {
        display: flex;
        justify-content: flex-end;
        img {
            margin-right: 20px;
        }
    }

    .remove-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .scroll-list-container {
        max-height: 500px;
        overflow-y: scroll;
    }

    .header-follow-container {
        @media only screen and (min-width: 1280px) {
            position: absolute;
            padding-right: 20px;
            width: 98.7%;
            z-index: 100;
            max-height: 65px;
        }
    }

    .list-body-follow-container {
        @media only screen and (min-width: 1280px) {
            padding-top: 55px;
        }
    }

    .row-list-container {
        flex-wrap: nowrap;
        margin-left: 0px;
        margin-right: 0px;
        min-width: 848px;
        &.header {
            background-color: #f6f9fc;
            color: #8898aa;
            padding-top: 15px;
            padding-bottom: 15px;
            span {
                font-size: 0.8rem;
            }
            @media only screen and (max-width: 1366px) {
                span {
                    font-size: 0.7rem;
                }
            }
            @media only screen and (max-width: 1280px) {
                span {
                    font-size: 0.55rem;
                }
            }
        }
        &.item-row {
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 1px solid #e9ecef;
            span {
                font-size: 0.8rem;
            }
            @media only screen and (max-width: 1366px) {
                span {
                    font-size: 0.7rem;
                }
            }
            @media only screen and (max-width: 1280px) {
                span {
                    font-size: 0.5rem;
                }
            }
        }
        .column-container {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .table__web {
        @include responsive(s) {
            display: none;
        }
    }

    .table__mobile {
        display: none;
        @include responsive(s) {
            display: block;
            table-layout: fixed;
            width: 100%;

            .table__mobile--header {
                width: 120px;
                color: #8898aa;
                background-color: #f6f9fc;
                color: #8898aa;
                border-right: 1px solid #e9ecef;
            }
        }
    }
}
