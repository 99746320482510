@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?fvj6uf');
  src:  url('fonts/icomoon.eot?fvj6uf#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fvj6uf') format('truetype'),
    url('fonts/icomoon.woff?fvj6uf') format('woff'),
    url('fonts/icomoon.svg?fvj6uf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-attach:before {
  content: "\e91a";
  color: #149372;
}
.icon-notification:before {
  content: "\e901";
  color: #149372;
}
.icon-filtro2:before {
  content: "\e918";
}
.icon-mais:before {
  content: "\e900";
  color: #149372;
}
.icon-check .path1:before {
  content: "\e902";
  color: rgb(20, 147, 114);
}
.icon-check .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-select:before {
  content: "\e904";
  color: #505050;
}
.icon-organizar:before {
  content: "\e905";
  color: #149372;
}
.icon-three-dots:before {
  content: "\e906";
  color: #a3a9ab;
}
.icon-drop .path1:before {
  content: "\e907";
  color: rgb(255, 255, 255);
}
.icon-drop .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(163, 169, 171);
}
.icon-download:before {
  content: "\e909";
  color: #149372;
}
.icon-lupa:before {
  content: "\e90a";
  color: #a3a9ab;
}
.icon-cifra:before {
  content: "\e90b";
  color: #149372;
}
.icon-trash-bin:before {
  content: "\e90c";
  color: #149372;
}
.icon-agrupar:before {
  content: "\e90d";
  color: #149372;
}
.icon-colunas:before {
  content: "\e90e";
  color: #149372;
}
.icon-calendar:before {
  content: "\e90f";
  color: #149372;
}
.icon-printer:before {
  content: "\e910";
  color: #149372;
}
.icon-eye-on:before {
  content: "\e911";
  color: #149372;
}
.icon-arquivado:before {
  content: "\e912";
  color: #149372;
}
.icon-eye-off:before {
  content: "\e913";
  color: #a3a9ab;
}
.icon-cinderela:before {
  content: "\e914";
  color: #cc8cf3;
}
.icon-tarde:before {
  content: "\e915";
  color: #50b7ea;
}
.icon-noite:before {
  content: "\e916";
  color: #507bea;
}
.icon-dia:before {
  content: "\e917";
  color: #e8a804;
}
.icon-filter:before {
  content: "\e919";
  color: #149372;
}
.icon-dowload2:before {
  content: "\e91b";
  color: #149372;
}
