.anticipation-values-search-doctor {
    min-width: 150px;
    padding: 0 3px;
    margin-right: 7px;

    label.MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(13px, 10px) scale(0.85) !important;
    }

    div.MuiFilledInput-root .MuiFilledInput-input {
        margin-top: -5px !important;
    }

    .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
        padding: 6px 4px !important;
    }

    .input-text > div {
        height: 50px !important;
    }
}

.MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-endAdornment {
    right: 15px;
    top: 4px;
}

.small-listbox {
    position: absolute;
    top: -4px;
    left: -1px;
    min-height: 50px;
    width: 154px;
    background: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    border-radius: 0 0 5px 5px;
    font-size: 13px;
    font-weight: 300;
    color: #404040;
    font-size: 13px;
}