.donut-chart {
    margin: auto;
    font-family: 'Open Sans';
    margin-top: 18px;

    .c3-chart-arcs {
        text {
            font-size: 12px;
            font-weight: 500;
            fill: #7B7B7B;
            font-family: 'Open Sans';
        }
    }
}
