//
// Icon
//

.icon {
	width: 3rem;
	height: 3rem;

	i, svg {
		font-size: 3rem - .75;
	}

    + .icon-text {
    	padding-left: 1rem;
    	width: calc(100% - #{3rem} - 1);
    }
}


// Extra large icons

.icon-xl {
	width: 5rem;
	height: 5rem;

	i, svg {
		font-size: 5rem - .75;
	}

    + .icon-text {
    	width: calc(100% - 5rem - 1);
    }
}


// Large icons

.icon-lg {
	width: 3rem;
	height: 3rem;

	i, svg {
		font-size: 3rem - .75;
	}

    + .icon-text {
    	width: calc(100% - 3rem - 1);
    }
}


// Small icon

.icon-sm {
	width: 2rem;
	height: 2rem;

	i, svg {
		font-size: 2rem - .75;
	}

    + .icon-text {
    	width: calc(100% - 2rem - 1);
    }
}
