.report-import__dropdown {
    filter: drop-shadow(-1px 0px 8px #d8d8d8);
    width: 239px;
    background-color: #fff8e4;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 14px;

    &--title {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 12px;
        color: #3e3e3e;
        text-align: center;
    }

    &--arrow {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #fff8e4;
        position: absolute;
        left: -10px;
        color: black;
    }

    &--arrowSecond {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #fff8e4;
        position: absolute;
        right: -10px;
    }

    &--buttons {
        display: flex;
        button {
            cursor: pointer;
            background: white;
            border: 1px solid #d8d8d8;
            border-radius: 4px;
            font-size: 12px;
            width: 62px;
            height: 26px;
            &:hover {
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
            }

            &:active {
                border-color: #c9c9c9;
                box-shadow: none;
            }
        }
        &-no {
            color: #fc4141;
            margin-right: 5px;
        }
        &-yes {
            color: #149372;
            margin-left: 5px;
        }
    }
}
