.notification__container {
  min-width: 422px;
  min-height: 60px;
  max-width: 422px;
  max-height: 580px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #E7E7E7;
  position: absolute;
  z-index: 50;
  right: 20px;
  overflow: auto;

  &--items-body {
    max-height: 544px;
  }

  &--items {
    position: relative;
    .triangle-img {
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 11px solid white;
      position: absolute;
      right: 17px;
      top: -10px;
      filter: drop-shadow(-1px -1px 1px #d8d8d8);
    }
    &-title {
      display: flex;
      height: 50px;
      padding: 0 22px;
      font-size: 16px;
      font-weight: 700;
      color: black;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #dcdcdc;
    }

    button {
      font-size: 12px;
      text-transform: uppercase;
      color: #149372;
      font-weight: 600;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
    }
  }
  &--line {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #e3e3e3;
    padding: 0 20px 0 25px;
    cursor: pointer;

    &:first-child {
      border: none;
    }

    &:last-child {
      border-bottom: 1px solid #e3e3e3;
    }

    &:active {
      background: #f4f4f4;
    }
  }

  &--line + &--line:active {
    border-color: transparent;
  }

  .notification-active {
    width: 8px;
    height: 8px;
    background: #e40b21;
    border-radius: 50%;
    margin-right: 16px;
  }

  .notification-disable {
    background: transparent;
  }
  .notification-title {
    font-size: 14px;
    color: #494a4a;
  }

  .notification-date {
    font-size: 12px;
    color: #a4a4a4;
  }
  .notification-description {
    font-size: 13px;
    color: #7b7b7b;
  }
  
}
