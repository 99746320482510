.check-icn {
    content: url('../../assets/img/svg/check.svg');
}

.selection-icn {
    margin-left: 10px;
    content: url('../../assets/img/svg/selection-icon-gray.svg');
}

.slot-icn {
    margin-left: 10px;
    content: url('../../assets/img/svg/gray-slot.svg');
}

.trash-icn {
    cursor: pointer;
    margin-left: 10px;
    content: url('../../assets/img/svg/delete-red.svg');
}

.loading-icn {}

label {
    padding-right: 20px !important;
}

@mixin mui-label-size($class-name,
$label-size) {
    .MuiFormControl-root {
        &#{$class-name} {
            .MuiInputBase-root {
                @if ($label-size <=50) {
                    height: $label-size + 35px;
                }
                @if ($label-size>50 and $label-size <=80) {
                    height: $label-size + 25px;
                }
                @if ($label-size>80 and $label-size <=110) {
                    height: $label-size + 15px;
                }
                @if ($label-size>110) {
                    height: $label-size - 25px;
                }
            }
        }
    }
}

@for $i from 40 through 255 {
    @include mui-label-size('.label-size-#{$i}',
    $i);
}

.grid-left-icon-wrapper {
    width: 100%;
    height: 58px;
    padding-top: 17px;
    border-right: unset;
    background-color: #F7F9FA;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: solid 1px #DFE4E6 !important;
    border-left: solid 1px #DFE4E6 !important;
    border-bottom: solid 1px #DFE4E6 !important;
}

.grid-left-icon-wrapper-complement {
    width: calc(100% - 37px);
    .MuiFormControl-root {
        .MuiFilledInput-root {
            border-left: unset !important;
            border-top-left-radius: unset !important;
            border-bottom-left-radius: unset !important;
        }
        .MuiFormHelperText-root {
            margin-left: -18px;
        }
    }
}

.helpText {
    width: 100%;
    padding: 10px;
    color: #4a4a4a;
    font-size: 13px;
    margin-top: -2px;
    text-align: center;
    position: relative;
    align-items: center;
    align-content: center;
    letter-spacing: 0.54px;
    background-color: #ffffff;
    border: solid 1px #e8ebec;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.blankHelpText {
    width: 100%;
}

.MuiFilledInput-underline::before {
    display: none;
}

.MuiSelect-select:focus {
    background-color: #f7f9fa !important;
}

// .MuiLabel-shrink {
//     background-color: #ffffff !important;
// }
.MuiFormHelperText-root.Mui-error {
    color: #e04444;
    letter-spacing: 0.54px;
    font-size: 12px !important;
}

.MuiFilledInput-root {
    .MuiSvgIcon-root {
        margin-bottom: 15px;
    }
}

.MuiFilledInput-underline::after {
    display: none !important;
}

.action {
    .MuiFilledInput-underline::after {
        display: none !important;
    }
}

.filled {
    .MuiInputBase-input:focus {
        border: none !important;
        // background-color: #ffffff !important;
    }
    .MuiFilledInput-inputMarginDense {
        padding: 2px 0 0 0;
    }
}

.MuiFormHelperText-root {
    color: #f44336 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #353738 !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #a3a9ab !important;
    font-weight: 500!important;
}

.error {
    .MuiFilledInput-root {
        top: 0;
        border-radius: 4px;
        background-color: #f7f9fa !important;
        //border-top: 2px solid #f44336 !important;
    }
    .MuiFormLabel-root.Mui-error {
        color: rgba(0, 0, 0, 0.54);
    }
    .MuiFilledInput-underline::after {
        top: 0;
        border-radius: 4px;
        border: none !important;
        //border-top: 2px solid #e04444 !important;
    }
    .MuiInputBase-input:focus {
        border: none !important;
        // background-color: #ffffff !important;
    }
}

.validate {
    .MuiFilledInput-underline:after {
        border-bottom: none;
    }
    .MuiFormLabel-root.Mui-error {
        color: #353738;
    }
    .MuiFilledInput-root {
        top: 0;
        border-radius: 4px;
        background-color: #f7f9fa !important;
        //border-top: 2px solid #5ab0ae !important;
    }
    .MuiInputBase-input:focus {
        border: none !important;
        // background-color: #ffffff !important;
    }
}

.MuiFormControl-root {
    input[type="date"] {
        color: transparent;
    }
    
    .Mui-focused {
        input[type="date"] {
            color: inherit;
        }
    }
    .MuiFilledInput-input:not([value=""]) {
        color: inherit;
    }
}

.floating-label {
    div.MuiFilledInput-root {
        background: white;
        border: solid 1px #D8D8D8;
        border-radius: 6px;
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 300;
        color: #404040;
        height: 36px;
        overflow: hidden;
        .MuiFilledInput-input {
            padding: 27px 12px 10px;
        }
    }
    label.MuiInputLabel-shrink {
        transform: translate(9px, 6px) scale(0.75) !important
    }
}