.small-loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .small-loading-wrap {
        border-radius: 50%;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #2d9187;
        animation: spin-animation 2s linear infinite;
        -webkit-animation: spin-animation 2s linear infinite;
    }

    .large-loading {
        width: 20px;
        height: 20px;
        
        border-radius: 50%;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #2d9187;
        animation: spin-animation 2s linear infinite;
        -webkit-animation: spin-animation 2s linear infinite;
    }
}

@-webkit-keyframes spin-animation {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}