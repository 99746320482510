#usuario-cadastro {

  .card {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    overflow: auto;
    height: 100%;
    margin-top: 50px !important;
  }

  .btn-primary {
    background-color: #009776;
    border: none;
    color: white;
    padding: 3px 37px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 26px;
    text-shadow: 0 0 0.2em #8F7;
  }

  .card-title {
    color: #00c4a3;
  }

  .campo-invalido {
    border: 1px solid red;
    border-radius: 0.375rem;
  }

  .b-r-1{
    border-radius: 1.375rem !important;
  }
  .w-95{
    width: 95% !important;
  }
}
